import { mapState, mapGetters } from 'vuex'
import Metrics from '../../../../../../../plugins/metrics'

import SButton from '../../../../../../../components/ui/s-button'
import STitle from '../../../../../../../components/ui/s-title'
import helpIcon from '../../../../../../../assets/images/help_icon.svg'

export default {
  name: 'AnonymousMode',
  props: {
    code: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      default: '',
    },
    channel: {
      type: String,
    },
  },

  components: {
    SButton,
    STitle,
  },

  data() {
    return {
      copied: false,
      copyText: 'copy',
      helpIcon,
    }
  },

  computed: {
    ...mapState(['isAnonymousUser']),
    ...mapGetters(['isSourceCollaborator']),

    isCollaborator() {
      return this.isSourceCollaborator(this.source)
    },
  },

  methods: {
    copy() {
      this.$emit('copy:code')
      this.copyText = 'copied'

      Metrics.track('Report Code Copied', {
        source: this.source,
        anonymous: true,
        channel: this.channel,
      })
    },

    proceedToLogin() {
      this.isAnonymousUser ? this.$emit('showLoginForm') : this.$emit('submit')
    },
  },
  watch: {
    copied() {
      if (!this.copied) {
        return
      }
      this.copy()
    },
  },
}
